var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-info-wrapper"},[_c('el-descriptions',{staticStyle:{"margin-bottom":"20px"},attrs:{"direction":"vertical","column":10,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"订单号","span":2,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.$route.params.id))]),_c('el-descriptions-item',{attrs:{"label":"订单类型","span":1,"label-class-name":"my-label"}},[(_vm.detail.orderType == 1)?_c('el-tag',{attrs:{"type":"primary","size":"mini"}},[_vm._v("普通订单 ")]):_vm._e(),(_vm.detail.orderType == 2)?_c('el-tag',{attrs:{"type":"warning","size":"mini"}},[_vm._v("门店订单 ")]):_vm._e(),(_vm.detail.orderType == 3)?_c('el-tag',{attrs:{"type":"success","size":"mini"}},[_vm._v("卡券订单 ")]):_vm._e()],1),(_vm.detail.orderGoods)?[_c('el-descriptions-item',{attrs:{"label":"商品描述","span":3,"label-class-name":"my-label"}},[_c('div',{staticClass:"handle"},[_c('span',[_vm._v(_vm._s(_vm.detail.orderGoods.skuTitle))]),_c('span',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.detail.status == 1 && _vm.isShowBtn(_vm.AUTH_BTN.order_history_list_update) && _vm.detail.orderType != 2),expression:"detail.status == 1 && isShowBtn(AUTH_BTN.order_history_list_update) && detail.orderType != 2"}],attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: 'OrderHistoryUpdateGoods',
                  params: { id: _vm.$route.params.id },
                  query: { memberId: _vm.$route.query.memberId }
                })}}},[_vm._v("修改")])],1)])])]:_vm._e(),(_vm.detail.orderGoods)?[_c('el-descriptions-item',{attrs:{"label":"成色","span":1,"label-class-name":"my-label"}},[_c('el-tag',{attrs:{"type":_vm.detail.orderGoods.goodsPurityName == '全新' ? 'primary' : 'warning',"size":"mini"}},[_vm._v(" "+_vm._s(_vm.detail.orderGoods.goodsPurityName)+" ")])],1)]:_vm._e(),_c('el-descriptions-item',{attrs:{"label":"订单状态","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.statusName))]),_c('el-descriptions-item',{attrs:{"label":"下单时间","span":2,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.createTime))]),_c('el-descriptions-item',{attrs:{"label":"费率","span":1,"label-class-name":"my-label"}},[_vm._v(" "+_vm._s(_vm.detail.priceRate)+" % ")]),_c('el-descriptions-item',{attrs:{"label":"平台服务费","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.servicePrice))]),_c('el-descriptions-item',{attrs:{"label":"保险金","label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.insurancePrice))]),_c('el-descriptions-item',{attrs:{"label":"溢价金","span":1,"label-class-name":"my-label","content-class-name":"my-content"}},[_c('div',{staticClass:"handle"},[_c('span',[_vm._v(_vm._s(_vm.detail.overflowPrice))])])]),_c('el-descriptions-item',{attrs:{"label":"零售价","span":1,"label-class-name":"my-label"}},[_c('div',{staticClass:"handle"},[_c('span',[_vm._v(" "+_vm._s(_vm.detail.salePrice))]),_c('span',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.isShowBtn(_vm.AUTH_BTN.order_history_list_detail_update_sale_price) && _vm.detail.goodsCombo == 'payAtOnce' && _vm.detail.status == 2
            ),expression:"\n              isShowBtn(AUTH_BTN.order_history_list_detail_update_sale_price) && detail.goodsCombo == 'payAtOnce' && detail.status == 2\n            "}],attrs:{"size":"mini","type":"primary"},on:{"click":_vm.updatePrice}},[_vm._v("修改")])],1)])]),_c('el-descriptions-item',{attrs:{"label":"总租金","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.rentPrice))]),_c('el-descriptions-item',{attrs:{"label":"首付租金","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.prepayRentPrice))]),_c('el-descriptions-item',{attrs:{"label":"租期","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.billPeriodNum)+" 期")]),_c('el-descriptions-item',{attrs:{"label":"买断金","span":1,"label-class-name":"my-label"}},[_vm._v(_vm._s(_vm.detail.buyoutPrice))])],2),_c('el-dialog',{attrs:{"title":"修改零售价","visible":_vm.periodDialog,"width":"800px"},on:{"update:visible":function($event){_vm.periodDialog=$event}}},[_c('el-form',{ref:"periodForm",attrs:{"model":_vm.priceData,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"新零售价"}},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"size":"small","precision":2,"min":0,"step":1,"step-strictly":""},model:{value:(_vm.priceData.salePrice),callback:function ($$v) {_vm.$set(_vm.priceData, "salePrice", $$v)},expression:"priceData.salePrice"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.periodDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.savePrice}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }