<template>
  <div class="wrapper">
    <div class="id-card">
      <div class="img">
        <template v-if="member">
          <el-image
            style="width: 100px; height: 100px;margin-right:20px;"
            :src="member.identityFrontImageUrl"
            :preview-src-list="[member.identityFrontImageUrl]"
          >
          </el-image>
          <el-image
            style="width: 100px; height: 100px;margin-right:20px;"
            :src="member.identityBackImageUrl"
            :preview-src-list="[member.identityBackImageUrl]"
          >
          </el-image>
        </template>
      </div>
      <div class="info">
        <template v-if="member">
          <el-descriptions direction="vertical" :column="6" border>
            <el-descriptions-item label="姓名" label-class-name="my-label">{{ member.memberName }}</el-descriptions-item>
            <el-descriptions-item label="用户库类别" :span="1" label-class-name="my-label">
              <span v-for="(item, i) in detail.rosterLevelList" :key="i">
                <el-tag v-if="item == 1" style="margin-right:5px" size="small" type="primary">白名单</el-tag>
                <el-tag v-if="item == 2" style="margin-right:5px" size="small" type="danger">黑名单</el-tag>
                <el-tag v-if="item == 3" style="margin-right:5px" size="small" type="info">灰名单</el-tag>
              </span>
              <el-tag v-if="detail.rosterLevelList == 0" style="margin-right:5px" size="small" type="primary">无数据</el-tag>
            </el-descriptions-item>
            <!-- <el-descriptions-item label="性别" label-class-name="my-label">{{ member.gradeName }}</el-descriptions-item> -->
            <el-descriptions-item label="年龄" label-class-name="my-label">{{ member.age }}</el-descriptions-item>
            <el-descriptions-item label="手机号" label-class-name="my-label">{{ member.mobile }}</el-descriptions-item>
            <el-descriptions-item label="身份证号" :span="2" label-class-name="my-label">{{ member.identityNoPlain }}</el-descriptions-item>
          </el-descriptions>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdCard',
  props: {
    member: {
      type: Object,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .id-card {
    display: flex;
    align-items: center;
    // .img {
    // }
    .info {
      flex: 1;
    }
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
